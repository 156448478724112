.wraper {
  grid-area: wraper;
  display: flex;
  justify-content: center;
  padding: 2rem 0;
  .container {
    max-width: 1280px;
    width: 100%;
  }
  &.--scrollX{
    overflow-x: scroll;
  }
}
