.topbar {
  grid-area: topbar;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-bottom: 1px solid var(--light);

  @media (max-width: 768px) {
    position: fixed;
    width: 100%;

    &__bot {
      overflow-x: scroll;
    }
  }

  &.d-none{
    display: none;
  }

  &__top {
    padding: 0.75rem;
    display: flex;
    div:first-of-type {
      width: 50%;
      display: inline;
      h1 {
        margin: 0;
        display: inline;
      }
    }
    div:last-of-type {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      div {
        display: flex;
        flex-direction: column;
        width: auto;
      }
      a {
        text-decoration: none;
        color: var(--dark);
        svg {
          font-size: 22px;
          padding-right: 10px;
        }
      }
      svg {
        font-size: 16px;
        padding-right: 10px;
      }
      p {
        margin: 0;
        color: var(--dark);
        font-weight: 300;
        font-size: 14px;
      }
      p:not(:first-child) {
        font-size: 12px;
        font-weight: 200;
      }
    }
  }

  &__bot {
    white-space: nowrap;
    max-width: 100vw;
    height: 100%;
    max-height: 100%;
    display: flex;
    align-content: center;
  }

  &__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-content: center;
    height: 100%;
  }

  &__item {
    display: inline;
    padding: 0.75rem 0;
    margin: 0 0.75rem;
    color: var(--secondary);
    &:hover {
      border-bottom: 1px solidvar(--dark);
      color: var(--dark);
      cursor: pointer;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  &__link {
    svg {
      padding-right: 5px;
    }
    text-decoration: none;
    color: var(--secondary);

    &:hover {
      color: var(--dark);
      &.--active {
        color: var(--dark);
      }
    }

    &.--active {
      color: var(--primary);
    }
  }
}
