.schedule {
  width: 100%;
  &__grid {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    div:last-child {
      color: var(--primary);
      cursor: pointer;
    }
  }
  &__day {
    grid-column: 1/2;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  &__time {
    grid-column: 2/3;
    display: flex;
    align-items: center;
    & > * {
      margin: 0 10px;
    }
    svg {
      cursor: pointer;
      color: var(--danger);
    }
  }
  &__add {
    grid-column: 3/4;
    margin-right: auto;
  }
}
