@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

:root {
  --primary: #1b3e77;
  --primaryScale1: #aadbeb;
  --secondary: #aadbeb;
  --success: #5eba00;
  --info: #49a8ee;
  --warning: #f1c40f;
  --danger: #cd201f;
  --light: #f0f3f8;
  --dark: #181d20;
  --white: #fff;
  --gray: #666666;
  --gray-light: #e0e4e9;
  --background: #f5f7fa;
  --boxShadow: rgba(69, 90, 100, 0.08) 0px 1px 20px 0px;
  --boxShadowHover: rgba(69, 90, 100, 0.3) 0px 10px 20px 0px;
  --neumorphism: 12px 12px 16px 0 rgba(0, 0, 0, 0.25),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
  font-family: 'Open Sans', sans-serif;
}
